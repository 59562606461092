import { useState, useEffect } from "react";
import { useAppContext } from "../../../Database/Context";
import { addNotification } from "../../../Components/Notification";


import AdminSidebarNavbar from "../../../Components/Admin/AdminSidebarNavbar";

import Token from "../../../Database/Token";
import Search from "../../../Components/Search";
import Table from "../../../Components/Table/Table";
import DeletePopup from "../../Others/DeletePopup/DeletePopup";
import colName from "./TableFormat.json";
import CreatePopUp from "../../../Components/PopUp/CreatePopUp/CreatePopUp";
import EditPopUp from "../../Others/EditPopup/EditPopup";


import editFormat from "./EditFormat.json";
import CreateFormat from "./CreateFormat.json";

export default function AdminState() {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { dispatch } = useAppContext();

  useEffect(() => {
    const callFunction = async () => {
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await Token.get(`states?search=${search}`);
        console.log(response);
        setData(response.data.data);
      } catch (error) {
        if (error.response) {
          addNotification(
            "Error",
            error.response.data.message || "An error occurred",
            "warning"
          );
        } else if (error.request) {
          addNotification(
            "Error",
            "No response received from server",
            "warning"
          );
        } else {
          addNotification(
            "Error",
            error.message || "An unknown error occurred",
            "warning"
          );
        }
      }
      dispatch({ type: "SET_LOADING", payload: false });
    };
    callFunction();
  }, [loading, search]);

  return (
    <AdminSidebarNavbar>
      <div className="h-[calc(100dvh-40px)] w-full p-4 overflow-y-auto">
        <Search className="mb-4" onSearch={(e) => setSearch(e)}></Search>
        <div>
          <Table
            data={data}
            button={
              <CreatePopUp
                title="Create State"
                format={CreateFormat}
                route="states/create/"
                onUpdate={()=>setLoading(!loading)}
              />
            }
          >
            <thead className="text-xs text-gray-100 uppercase bg-orange-500">
              <tr>
                {colName.length !== 0 &&
                  colName.map((data, index) => (
                    <th
                      key={index}
                      scope="col"
                      colSpan={1}
                      className="duration-300 pl-2 sm:pl-1 py-2 group h-14 min-w-[75px]"
                    >
                      {data.title}
                    </th>
                  ))}
              </tr>
            </thead>

            <tbody>
              {data.length === 0 && (
                <tr>
                  <td
                    colSpan={colName.length}
                    className="text-center text-bold text-xl text-gray-800 uppercase py-4 border-[1.5px] border-gray-300"
                  >
                    Data not found
                  </td>
                </tr>
              )}
              {data.length !== 0 &&
                data.map((list, rowIndex) => (
                  <tr
                    className={`pl-2 text-slate-900 border-y-[1.7px] hover:bg-[#f1f1f1]`}
                    key={rowIndex}
                  >
                    {colName.map((data, colIndex) => (
                      <>
                        {data.status && (
                          <th
                            className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                            scope="row"
                            key={colIndex}
                          >
                            {list[data.target]}
                          </th>
                        )}
                      </>
                    ))}
                    <th
                      className="px-4 py-4 md:px-4 sm:px-2 font-medium flex flex-row gap-1"
                      scope="row"
                    >
                      <EditPopUp
                        route={`/states/update/` + list.id + "/"}
                        title="Edit User"
                        onLoading={() => setLoading(!loading)}
                        format={editFormat}
                        data={list}
                        alert="User has been updated"
                      />
                      <DeletePopup
                        id={list.id}
                        route="/states/delete/"
                        onLoading={() => setLoading(!loading)}
                      />
                    </th>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </AdminSidebarNavbar>
  );
}

import { useState, useEffect } from "react";
import { addNotification } from "../../../Components/Notification";
import Token from "../../../Database/Token";
import Loading from "../../../Assets/SVG/Loading";
import Eye from "../../../Assets/SVG/Eye";
import EyeSlash from "../../../Assets/SVG/EyeSlash";
import AdminSidebarNavbar from "../../../Components/Admin/AdminSidebarNavbar";

export default function AdminAccount(){
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const [account, setAccount] = useState("123456789012");
  const [accountEye, setAccountEye] = useState(true);

  const [aadhar, setAadhar] = useState("1234567890123456");
  const [aadharEye, setAadharEye] = useState(true);

  const [pan, setPan] = useState("1234567890");
  const [panEye, setPanEye] = useState(true);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
    console.log(user)
    setName(user.name)
    setEmail(user.email)
    setPhone(user.phone)
  }, []);
  const callFunction = async () => {
    setLoading(true);
    const jsonData = {
      name: name,
      email: email,
      phone: phone,
    };
    try {
      const response = await Token.patch("/user/update/", jsonData);
      console.log(response);
      if (response.data.status) {
        localStorage.removeItem("user");
        const data = JSON.stringify(response.data.user);
        console.log(data);
        localStorage.setItem("user", data);
        addNotification("Updated", "Your profile has been updated", "success");
      }
    } catch (e) {
      // addNotification("error", e.message, "danger");
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <AdminSidebarNavbar>
      <div className="h-[calc(100%-20px)] w-full p-4">
        <div className="w-full bg-white shadow-md rounded-md p-6 pt-10">
          <h1 className="text-3xl font-bold h-16">
            Welcome <p className="text-orange-500">{name}</p>
          </h1>
          <p className="font-bold mt-3 mb-1 ">Personal Information</p>
          <div className="w-full h-[0.5px] opacity-40 bg-gray-600 shadow-md"></div>

          <div className="grid grid-cols-2 sm:grid-cols-1 max-w-full min-w-0 gap-4">
            <div>
              <p className="font-bold mt-2">Name</p>
              <input
                type="text"
                className="w-full h-10 bg-[#f4f6f8] border-[1px] border-gray-300 rounded-sm outline-none focus:border-orange-500 px-2 focus:border-[1.75px]"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <p className="font-bold mt-2">Email</p>
              <input
                type="text"
                className="w-full h-10 bg-[#f4f6f8] border-[1px] border-gray-300 rounded-sm outline-none focus:border-orange-500 px-2 focus:border-[1.75px]"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <p className="font-bold mt-2">Phone Number</p>
              <input
                type="text"
                className="w-full h-10 bg-[#f4f6f8] border-[1px] border-gray-300 rounded-sm outline-none focus:border-orange-500 px-2 focus:border-[1.75px]"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            {/* <div>
              <p className="font-bold mt-2">MPIN</p>
              <div className="group relative">
                <input
                  type={mpinEye ? "password" : "text"}
                  className="h-10 w-full rounded-s-sm bg-[#f4f6f8] border-gray-300 focus:border-[1.75px] border-[1px]  outline-none px-2 focus-within:border-orange-500 group-focus:border-orange-500 float-left"
                  onChange={(e) => setMpin(e.target.value)}
                  value={mpin}
                />
                <div
                  className="absolute right-[0%] w-10 h-10 hover:border-[1.5px] border-l-0 flex items-center justify-center hover:border-orange-500 hover:border-l-[2px] group-focus:border-orange-500 rounded-e-sm"
                  onClick={() => setMpinEye(!mpinEye)}
                >
                  {!mpinEye && <Eye />}
                  {mpinEye && <EyeSlash />}
                </div>
              </div>
            </div> */}
          </div>
          {!loading && (
            <button
              className="py-2 mt-4 rounded-md text-white text-xl font-bold px-10 bg-orange-500 shadow-md hover:bg-orange-600 focus-visible:ring-2 focus-visible:ring-offset-2 ring-orange-500 outline-none focus-visible:bg-orange-500"
              onClick={callFunction}
            >
              Save
            </button>
          )}
          {loading && (
            <button className="py-2 mt-4 rounded-md text-white text-xl font-bold px-12 bg-orange-500 shadow-md hover:bg-orange-600 focus-visible:ring-2 focus-visible:ring-offset-2 ring-orange-500 outline-none focus-visible:bg-orange-500">
              <Loading />
            </button>
          )}
        </div>

        <div className="w-full bg-white shadow-md rounded-md p-6 pt-10 mt-4">
          <h1 className="text-3xl font-bold h-16">Account Details</h1>
          <p className="font-bold mt-3 mb-1 ">Information</p>
          <div className="w-full h-[0.5px] opacity-40 bg-gray-600 shadow-md"></div>

          <div className="grid grid-cols-2 sm:grid-cols-1 max-w-full min-w-0 gap-4">
            <div>
              <p className="font-bold mt-2">Account Number </p>
              <div className="group relative">
                <input
                  type={accountEye ? "password" : "text"}
                  className="h-10 w-full rounded-s-sm bg-[#f4f6f8] border-gray-300 focus:border-[1.75px] border-[1px]  outline-none px-2 focus-within:border-orange-500 group-focus:border-orange-500 float-left"
                  value={account}
                />
                <div
                  className="absolute right-[0%] w-10 h-10 hover:border-[1.5px] border-l-0 flex items-center justify-center hover:border-orange-500 hover:border-l-[2px] group-focus:border-orange-500 rounded-e-sm"
                  onClick={() => setAccountEye(!accountEye)}
                >
                  {!accountEye && <Eye />}
                  {accountEye && <EyeSlash />}
                </div>
              </div>
            </div>
            <div>
              <p className="font-bold mt-2">Aadhar Number</p>
              <div className="group relative">
                <input
                  type={aadharEye ? "password" : "text"}
                  className="h-10 w-full rounded-s-sm bg-[#f4f6f8] border-gray-300 focus:border-[1.75px] border-[1px]  outline-none px-2 focus-within:border-orange-500 group-focus:border-orange-500 float-left"
                  value={aadhar}
                />
                <div
                  className="absolute right-[0%] w-10 h-10 hover:border-[1.5px] border-l-0 flex items-center justify-center hover:border-orange-500 hover:border-l-[2px] group-focus:border-orange-500 rounded-e-sm"
                  onClick={() => setAadharEye(!aadharEye)}
                >
                  {!aadharEye && <Eye />}
                  {aadharEye && <EyeSlash />}
                </div>
              </div>
            </div>
            <div>
              <p className="font-bold mt-2">Pan Number</p>
              <div className="group relative">
                <input
                  type={panEye ? "password" : "text"}
                  className="h-10 w-full rounded-s-sm bg-[#f4f6f8] border-gray-300 focus:border-[1.75px] border-[1px]  outline-none px-2 focus-within:border-orange-500 group-focus:border-orange-500 float-left"
                  value={pan}
                />
                <div
                  className="absolute right-[0%] w-10 h-10 hover:border-[1.5px] border-l-0 flex items-center justify-center hover:border-orange-500 hover:border-l-[2px] group-focus:border-orange-500 rounded-e-sm"
                  onClick={() => setPanEye(!aadharEye)}
                >
                  {!panEye && <Eye />}
                  {panEye && <EyeSlash />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminSidebarNavbar>
  );
};

import { useState } from "react";

export default function Search(props) {
  const [search, setSearch] = useState("");

  const handleInputChange = (event) => {
    setSearch(event.target.value);
    handleKeyPress(event);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      props.onSearch(search);
    }
  };

  return (
    <div className="h-12 rounded-md shadow-md bg-white mb-4">
      <input
        type="text"
        className="w-[calc(100%-82.8px)] border-[1.5px] px-2 outline-none border-[#f97316] h-10 my-1 ml-1 rounded-l-md float-left"
        onChange={handleInputChange}
        value={search}
        onKeyDown={handleKeyPress}
        placeholder="Search..." 
      />
      <button className="h-10 bg-[#f97316] text-white px-3 my-1 float-right mr-1 text-center rounded-r-md" onClick={()=>setSearch(search)}>
        Search
      </button>
    </div>
  );
}

import { useEffect, useState } from "react";
import Input from "../../Components/Input/Input";
import Token from "../../Database/Token";
import CreditCardPay from "../Others/CreditCardPay/CreditCardPay";

export default function PaymentBar() {
    const [usage,setUsage]= useState(false);
  useEffect(() => {
    const callFunction = async () => {
      try {
        const response = await Token.get("credit-card-usage/");
        if (response.data.status) {
          setUsage(response.data.data)
        } else {
          console.log(response.data.message);
        }
      } catch (e) {
        console.log(e.message);
      }
    };
    callFunction();
  }, []);
  return (
    <div className="bg-white mt-4  rounded-md p-4" key={usage}>
      <p>Left Amount</p>
      {/* <input type="text" readOnly /> */}
      <div className=" grid grid-cols-[1fr_100px] gap-4">

      <Input type="password" defaultValue={usage} readOnly={true}/>
      <CreditCardPay route="/pay-credit-card-bills/" />
      </div>
    </div>
  );
}

import Moment from "react-moment";
import { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate, useLocation } from "react-router-dom";
import { addNotification } from "../../Components/Notification";

import UPIN from "../../Components/User/UPIN";
import Token from "../../Database/Token";
import Spin from "../../Assets/SVG/Spin";

import UserSidebarNavbar from "../../Components/User/UserSidebarNavbar";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import RotateRightIcon from "@mui/icons-material/RotateRight";

import Amount from "./Amount";
export default function Home() {
  const history = useNavigate();
  const [scannerResult, setScannerResult] = useState(false);
  const [amount, setAmount] = useState("");
  const [start, setStart] = useState(false);
  const [pin, setPin] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("loading");
  const [data, setData] = useState([]);
  const [type, setType] = useState("DC");
  const location = useLocation();

  const getQueryParams = (queryString) => {
    return new URLSearchParams(queryString);
  };

  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    let user = queryParams.get("user");
    const upi_id = queryParams.get("upi_id");

    if (user && upi_id && !scannerResult) {
      user = user.replace("%", " ");
      setName(user);
      setNumber(upi_id);
      setScannerResult(true);
    }
  }, [location.search]);

  const sendMoney = (e) => {
    console.log(e);
    setAmount(e);
    setStart(true);
  };

  const onPinSubmit = async (e) => {
    setLoading(true);
    setPin(e);
    const jsonData = {
      upi_id: number,
      mpin: e,
      amount: Number(amount),
      type: type,
    };
    console.log(jsonData);
    try {
      const response = await Token.post("/send-money/", jsonData);
      if (response.data.status) {
        setStatus("completed");
        setData(response.data.transaction);
        addNotification("Send", "Money has been sended", "success");
      } else {
        addNotification("Invalid", response.data.message, "warning");
        setStatus("canceled");
      }
    } catch (error) {
      setLoading(false);
      setStatus("canceled");
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    }
    setLoading(false);
  };

  const handleScan = (e) => {
    const scannedText = e?.text ? String(e.text) : "";
    console.log("Scanned Text:", scannedText);

    // Find `user` and `upi_id` values using regular expressions
    const userMatch = scannedText.match(/user=([^&]+)/);
    const upiIdMatch = scannedText.match(/upi_id=([^&]+)/);

    // Check if both values were found
    if (userMatch && upiIdMatch) {
        // Extract the actual values
        const user = userMatch[1].replace("%", " ");
        const upi_id = upiIdMatch[1];

        setScannerResult(true);
        setNumber(upi_id);
        setName(user);
    } else {
        console.error("Invalid QR code format or missing parameters.");
    }
};

  return (
    <UserSidebarNavbar>
      <div className="h-[calc(100dvh-40px)] w-full p-4 overflow-y-auto">
        {!scannerResult && (
          <QrReader
            delay={300}
            onError={console.error}
            className="w-full h-screen"
            onResult={handleScan}
            constraints={{ facingMode: "environment" }}
          />
        )}
        {scannerResult && !start && (
          <Amount name={name} sendMoney={sendMoney} type={setType} />
        )}
        {start && !pin && (
          <div>
            <h1 className="font-bold text-center text-5xl sm:text-4xl text-orange-500 my-3">
              Enter MPIN
            </h1>
            <UPIN length={6} onPinSubmit={onPinSubmit} />
          </div>
        )}
        {pin && (
          <div className="flex flex-col items-center justify-center">
            {status === "completed" && (
              <CheckCircleIcon
                style={{ width: "200px", height: "200px", color: "#f97316" }}
              />
            )}
            {status === "canceled" && (
              <CancelIcon
                style={{ width: "200px", height: "200px", color: "#f97316" }}
              />
            )}
            {status === "loading" && (
              <RotateRightIcon
                style={{ width: "200px", height: "200px", color: "#f97316" }}
                className="animate-spin"
              />
            )}
            {loading && <Spin />}
            <h1 className="font-bold text-7xl">₹{amount}</h1>
            <h2 className="text-2xl">
              Paid to{" "}
              <label className="font-bold text-orange-500">{name}</label>
            </h2>
            <p className="mt-10">
              <Moment format="DD MMMM YYYY, hh:mm:ss A">
                {data.create_at}
              </Moment>
            </p>
            <div className="grid grid-cols-2 gap-6 mt-5">
              <div>
                <button
                  className="w-52 float-right h-14 rounded-full bg-orange-500 text-white text-2xl sm:w-40 focus-visible:ring-2 ring-offset-2 ring-orange-500 outline-none"
                  onClick={() => history("/home")}
                >
                  Go to Home
                </button>
              </div>
              <button
                className="w-52 h-14 rounded-full border-2 border-orange-500 text-orange-500 sm:w-40 text-2xl font-medium outline-none focus-visible:ring-2 ring-offset-2 ring-orange-500"
                onClick={() => window.location.reload()}
              >
                Continue
              </button>
            </div>
          </div>
        )}
      </div>
    </UserSidebarNavbar>
  );
}

import React from "react";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReportIcon from '@mui/icons-material/Report';
import PersonIcon from "@mui/icons-material/Person";


import {useNavigate} from 'react-router-dom'
export default function WorkList() {
    const history = useNavigate();
  const data = [
    { name: "Scan any QR code", route:"/home/scan", icon: <QrCodeScannerIcon/> },
    { name: "See Your QR code", route:"/home/qr-code", icon: <QrCodeOutlinedIcon/> },
    { name: "Crdit Card", route:"/home/Credit-Card", icon: <CreditCardOutlinedIcon/> },
    { name: "Phone Number", route:"/home/send-money-by-phone", icon:  <PhoneIphoneIcon/>},
    { name: "Transaction", route:"/home/Transaction", icon: <ReceiptIcon/> },
    { name: "Report", route:"/home/report", icon: <ReportIcon/> },
    { name: "Account", route:"/home/account", icon: <PersonIcon/> },
    // { name: "QR", route:"/qr", icon:"QR" },
    // { name: "QR", route:"/qr", icon:"QR" },
    // { name: "QR", route:"/qr", icon:"QR" },
    // { name: "QR", route:"/qr", icon:"QR" },
    // { name: "QR", route:"/qr", icon:"QR" },
    // { name: "QR", route:"/qr", icon:"QR" },
    // { name: "QR", route:"/qr", icon:"QR" },
    // { name: "QR", route:"/qr", icon:"QR" },
    // { name: "QR", route:"/qr", icon:"QR" },
    // { name: "QR", route:"/qr", icon:"QR" },
  ];

  return (
    <div className="float-left w-full flex flex-row scroll-smooth pl-1 overflow-x-auto scroll-bar">
      {data.map((e, index) => (
        <div className="w-16 py-2 mx-[6px] group text-white" onClick={()=>history(e.route)}>
            <div key={index} className="w-14 h-14 rounded-full bg-[#f97316] group-hover:bg-[#e1550c] flex items-center justify-center">{e.icon}</div>
            <p className="text-center max-w-16 text-sm text-black">{e.name}</p>
        </div>
      ))}
    </div>
  );
}

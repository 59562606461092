// import Image from "../../Assets/Images/Logo/Logo.png";
import Axios from "../Database/Axios";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { addNotification } from "../Components/Notification";

import Eye from "../Assets/SVG/Eye";
import EyeSlash from "../Assets/SVG/EyeSlash";
import Spin from "../Assets/SVG/Spin";

export default function AdminLogin() {
  const history = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [eye, setEye] = useState(true);
  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const info = localStorage.getItem("remember-admin");
    if (info) {
      history("/admin/dashboard");
    }
  }, []);
  const validate = () => {
    if (username === "") {
      addNotification(
        "field is empty",
        "you need to enter valid username",
        "warning"
      );
      return false;
    }
    if (password === "") {
      addNotification(
        "field is empty",
        "you need to enter password address",
        "warning"
      );
      return false;
    }
    return true;
  };
  const loginFunction = async () => {
    if (!validate()) {
      setLoading(false);
      return;
    }
    const data = {
      username: username,
      password: password,
    };
    try {
      setLoading(true);
      const response = await Axios.post("/admin-login/", data);
      setLoading(false);
      console.log(response.data)
      if (response.data.status) {
        addNotification(
          "Check",
          `Check Your email box`,
          "success"
        );
        localStorage.setItem("accessToken", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.data));

        if (remember) {
          localStorage.setItem("remember-admin", true);
        }
        const location = "/otp/" + username;
        history(location, { state: { fromAdmin: true,data: data } });
      } else {
        addNotification("wrong", response.data.message, "warning");
      }
    } catch (e) {
      setLoading(false);
      addNotification("Error", e.message, "danger");
    }
  };
  return (
    <div className="flex items-center justify-center bg-[#e4e3e3] h-dvh p-3">
      <div className="p-5 max-w-[400px] w-[400px] min-w-[200px] bg-white shadow-md rounded-md">
        <h1 className="text-center text-5xl font-bold my-3 text-orange-500">
          Admin Login
        </h1>
        <p>Username</p>
        <input
          type="username"
          className="w-full h-10 outline-orange-500 border-gray-300 rounded-sm px-2 border-[2px]"
          placeholder="Enter Username"
          onChange={(e) => setUsername(e.target.value)}
        />
        <p className="mt-1">Password</p>
        <div className="group relative">
          <input
            type={eye ? "password" : "text"}
            className="h-10 w-full rounded-s-sm border-gray-300 border-[2px] outline-none px-2 focus-within:border-orange-500 group-focus:border-orange-500 float-left"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            placeholder="Enter password"
          />
          <div
            className="absolute right-[0%] w-10 h-10 hover:border-[1.5px] border-l-0 flex items-center justify-center hover:border-orange-500 hover:border-l-[2px] group-focus:border-orange-500 rounded-e-sm"
            onClick={() => setEye(!eye)}
          >
            {!eye && <Eye />}
            {eye && <EyeSlash />}
          </div>
        </div>
        <div className="h-2 "></div>
        <div className="mt-1 w-full">
          <input
            type="checkbox"
            placeholder=""
            className="h-4 accent-orange-500 w-4 float-left my-1 text-white"
            onClick={() => setRemember(!remember)}
          />
          <p className="float-left ml-1 h-5">Remember me</p>{" "}
          <p
            className="float-right cursor-pointer hover:text-gray-600"
            onClick={() => history("/forgot-request")}
          >
            Lost your password?
          </p>
        </div>
        <div className="h-2"></div>
        {loading && <br />}
        {!loading && (
          <button
            className="h-9 bg-orange-500 w-full text-white rounded-sm outline-none focus-within:bg-orange-600 hover:bg-orange-600 my-2"
            onClick={loginFunction}
          >
            Login
          </button>
        )}
        {loading && (
          <button className="h-9 bg-orange-500 w-full text-white rounded-sm outline-none focus-within:bg-orange-600 hover:bg-orange-600 flex items-center justify-center my-2">
            <Spin />
          </button>
        )}
      </div>
    </div>
  );
}

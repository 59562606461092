import { useEffect, useState } from "react";
import Eye from "../../Assets/SVG/Eye";
import EyeSlash from "../../Assets/SVG/EyeSlash";
import Token from "../../Database/Token";

export default function Balance() {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Token.get("see-balance");
        if (response.data.status) {
          setBalance(response.data.balance);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);
  const [eye, setEye] = useState(true);
  const [balance, setBalance] = useState(0);
  return (
    <div className="w-full bg-white shadow-md rounded-md p-6 pt-10 mt-4 h-auto">
      <h1 className="text-3xl font-bold h-8">Your Balance</h1>
      <p className="font-bold mt-3 mb-1 ">See your balance </p>

      <div className="w-full h-[0.5px] opacity-40 bg-gray-600 shadow-md"></div>
      <div className="relative h-16">
        <p className="font-bold mt-2">Account Number </p>
        <div className="group relative">
          <input
            type={eye ? "password" : "text"}
            className="h-10 w-full rounded-s-sm bg-[#f4f6f8] border-gray-300 focus:border-[1.75px] border-[1px]  outline-none px-2 focus-within:border-orange-500 group-focus:border-orange-500 float-left"
            value={balance}
          />
          <div
            className="absolute right-[0%] w-10 h-10 hover:border-[1.5px] border-l-0 flex items-center justify-center hover:border-orange-500 hover:border-l-[2px] group-focus:border-orange-500 rounded-e-sm"
            onClick={() => setEye(!eye)}
          >
            {!eye && <Eye />}
            {eye && <EyeSlash />}
          </div>
        </div>
      </div>
    </div>
  );
}

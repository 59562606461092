import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import UPIN from "../Components/User/UPIN";
import Axios from "../Database/Axios";
import Spin from "../Assets/SVG/Spin";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { addNotification } from "../Components/Notification";

export default function OTP() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [loading, setLoading] = useState(false);

  const onPinSubmit = async (e) => {
    setLoading(true);
    const data = {
      username: params.username,
      otp: e,
    };

    try {
      const response = await Axios.post("/verify_otp/", data);
      console.log(response.data);
      if (response.data.status) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("account", JSON.stringify(response.data.account));
        localStorage.setItem(
          "credit_card",
          JSON.stringify(response.data.credit_card)
        );
        localStorage.setItem(
          "credit_card_data",
          JSON.stringify(response.data.credit_card_data)
        );
        if (location.state?.fromAdmin) {
          navigate("/admin/dashboard");
        } else {
          navigate("/home");
        }
      } else {
        addNotification("warning", "OTP is not valid", "warning");
      }
    } catch (error) {
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    }
    setLoading(false);
  };

  const resendOTP = async () => {
    try {
      const response = await Axios.post(
        location.state?.fromAdmin ? "/admin-login/" : "/login/",
        {
          username: params.username,
          password: location.state?.data.password,
        }
      );
      if (response.data.status) {
        addNotification("Success", "OTP has been resent", "success");
      } else {
        addNotification("warning", response.data.message, "warning");
      }
    } catch (error) {
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    }
  };

  return (
    <div className="bg-[#e4e3e3] h-screen flex items-center justify-center">
      <div className="">
        <div className="w-[350px] h-[400px] shadow-md rounded-md bg-white p-3">
          <div className="text-center">
            <MarkEmailReadIcon
              style={{ color: "#f97316", width: "100px", height: "100px" }}
            />
          </div>
          <p className="text-center font-bold">Check your email box</p>
          <p className="text-center">We send OTP to your email box</p>
          <UPIN length={6} onPinSubmit={onPinSubmit} />
          <p className="text-center mt-[3px]">
            Didn't get the code?{" "}
            <a
              className="text-orange-500 font-semibold cursor-pointer"
              onClick={() => resendOTP()}
            >
              Click to resend.
            </a>
          </p>
          <div className="grid grid-cols-2 gap-3 mt-5">
            <button
              className="text-center h-12 text-gray-500 text-xl rounded-lg  border-2 border-gray-300"
              onClick={() => navigate(-1)}
            >
              back
            </button>
            {!loading && (
              <button
                className="text-center h-12 bg-orange-500 text-white text-xl rounded-lg"
                onClick={onPinSubmit}
              >
                Verify
              </button>
            )}
            {loading && (
              <button className="text-center h-12 bg-orange-500 text-white text-xl rounded-lg flex items-center justify-center">
                <Spin />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import UserSidebarNavbar from "../../Components/User/UserSidebarNavbar";
import DashboardCard from "../../Components/User/DashboardCard";
import Graph from "../../Components/User/Graph";

import WorkList from "../../Components/User/WorkList";

export default function Home() {
  const formatTitle = [
    {
      title: "DC Transaction",
      color: "bg-[#f97316]",
      api: "/user/transaction-statistics-self-debit-card/",
    },
    {
      title: "CC Transaction",
      color: "bg-[#f97316]",
      api: "/user/transaction-statistics-self-credit-card/",
    },
  ];
  return (
    <UserSidebarNavbar>
      <div className="overflow-x-auto w-full shadow-md">
        <WorkList />
      </div>
      <div className="gap-4 grid grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 px-4 pt-4">
        {formatTitle.map((list, index) => (
          <div key={index}>
            <DashboardCard
              key={index}
              title={list.title}
              color={list.color}
              api={list.api}
            />
          </div>
        ))}
      </div>
      <div className="md:grid-cols-1 gap-4 md:gap-1 my-4 sm:grid-cols-1 grid grid-cols-2 px-4">
        <Graph
          title="Debit Card Transactions"
          api="/all/transaction-monthly-summary-debit-card/"
        />
        <Graph
          title="Credit Cart Transactions"
          api="/all/transaction-monthly-summary-credit-card/"
        />
      </div>
    </UserSidebarNavbar>
  );
}

import { useState } from "react";
// import SendIcon from "@mui/icons-material/Send";
import UserSidebarNavbar from "../Components/User/UserSidebarNavbar";
import Token from "../Database/Token";
import { addNotification } from "../Components/Notification";

export default function Report() {
  const [report, setReport] = useState("");
  const sendReport = async ()=>{
    try{
      const jsonData = {
        message: report
      }
      const response = await Token.post('/report/create/',jsonData);
      console.log(response.data)
      if(response.data.status){
        addNotification('success', response.data.message,'success')
      }else{
        addNotification('warning', response.data.message,'warning')
      }
    }catch(e){
      console.log(e)
    }
  }
  return (
   <UserSidebarNavbar>
     <div className="m-4 bg-white shadow-md rounded-md p-4">
     <p>Hey, If you have any issue with website then send us we will contact to email address.</p>
     <textarea
       className="w-full h-32 border-[1px] border-gray-300 p-3 bg-[#f4f6f8] outline-none focus:border-orange-500 focus:border-2 mt-1 rounded-sm"
       placeholder="Write your report here"
       value={report}
       onChange={(e) => setReport(e.target.value)}>

       </textarea>
       <button
          className="w-[100px] h-10 bg-orange-500 text-white rounded-md text-sm font-bold hover:bg-orange-600 transition duration-300 mt-4"
          onClick={() => sendReport()}
        >
          Send
        </button>
        <button
          className="w-[100px] h-10 bg-[#f4f6f8] text-gray-700 border-[1px] border-gray-300 rounded-md text-sm font-bold transition duration-300 mt-4 ml-4"
          onClick={() => setReport("")}
        >
          Clear
        </button>
    </div>
   </UserSidebarNavbar>
  );
}

import { Tooltip, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const LinkToTag = (props) => {
  const [isUserType, setIsUserType] = useState("");
  const location = useLocation();
  const history = useNavigate();
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
  }, []);

  const handleSideBarIcons = () => {
    const path = props.route;
    const currentPath = location.pathname;
    let styles = {
      padding: "15px",
    };
    if (currentPath === path) {
      styles = {
        padding: "25px",

        border: "2px solid #f97316  ",
        borderRadius: "50%",
        backgroundColor: "#f97316",
        color: "white",
        width: "30px",
        height: "30px",
      };
    }
    return styles;
  };

  const buttonClickFunction = () => {
    // console.log("this is logic")
    history(props.route);
  };
  return (
    <div onClick={buttonClickFunction} className={props.className}>
      <Tooltip title={props.title} arrow placement={props.placement}>
        <IconButton style={handleSideBarIcons()}>{props.children}</IconButton>
      </Tooltip>
    </div>
  );
};

export default LinkToTag;

import { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";

export default function Amount({ name, sendMoney = (e) => {} ,type=()=>{}}) {
  const [amount, setAmount] = useState("");
  const [typeTransaction,setTypeTransaction] = useState("DC");
  const [local,setLocal] = useState(false)

  const changeType = ()=>{
    setTypeTransaction(typeTransaction==="DC"?"CC":"DC")
    type(typeTransaction==="DC"?"CC":"DC")

  }
  useEffect(()=>{
    const localData = JSON.parse(localStorage.getItem('credit_card'))
    if(localData){
      setLocal(true)
    }
  },[])

  return (
    <div>
      <div className="grid grid-cols-[1fr_150px] sm:grid-cols-1 w-full">
        <h1 className="font-bold text-center text-5xl sm:text-4xl text-orange-500 my-3">
          Send Money to <br /> {name}
        </h1>
        <div className="">
        {local &&<div className="min-h-full h-10 m-4">
          <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" className="sr-only peer" onClick={changeType} />
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-orange-300 dark:peer-focus:ring-orange-400 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-300"></div>
            <span className="ms-3 text-sm font-medium text-gray-600">{typeTransaction === "DC"?"Debit Card":"Credit Card"}</span>  
          </label>
        </div>}
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className="mx-6 my-10 w-full">
          <input
            type="number"
            className="h-14 outline-orange-500 border-gray-300 rounded-sm px-2 border-[2px] float-left min-w-0 w-[calc(100%-72px)] text-2xl"
            placeholder="Enter Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <button
            className="h-14 bg-orange-500 text-white px-6 float-right focus:bg-orange-600"
            onClick={() => sendMoney(amount)}
          >
            <SendIcon style={{ scale: "150%" }} />
          </button>
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import Axios from "../../Database/Axios";

export default function SelectCity({onClick=(e)=>{}}) {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await Axios.get(`/states/`);
        if (response.data.status) {
          setStates(response.data.data);
        }
        console.log(response);
      } catch (e) {
        console.log(e);
      }
    };

    fetchStates();
  }, []);

  const fetchCities = async (stateId) => {
    try {
      const response = await Axios.get(`/states/${stateId}/cities/`);
      if (response.data.status) {
        setCities(response.data.data);
      }
      console.log(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleStateChange = (e) => {
    const stateId = e.target.value;
    setSelectedState(stateId);
    if (stateId) {
      fetchCities(stateId);
    } else {
      setCities([]);
    }
  };

 
  return (
    <>
      <div>
        <p>State</p>
        <select
          name="state"
          id="state"
          onChange={handleStateChange}
          className="border-[1px] bg-[#f4f6f8] h-10 focus:border-[1.75px] border-gray-300 w-full outline-none"
        >
          <option value="">Select State</option>
          {states.map((state) => (
            <option key={state.id} value={state.id}>
              {state.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <p>City</p>
        <select
          name="city"
          id="city"
          onChange={onClick} // Event handler for city selection
          className="w-full outline-none border-[1px] bg-[#f4f6f8] h-10 focus:border-[1.75px] border-gray-300"
        >
          <option value="">Select City</option>
          {cities.map((city) => (
            <option key={city.id} value={city.id}>
              {city.name}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

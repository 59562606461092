import AdminSidebarNavbar from "../../../Components/Admin/AdminSidebarNavbar";
import DashboardCard from "../../../Components/User/DashboardCard";
import Graph from "../../../Components/User/Graph";
import format from "./CardFormat.json";
import GraphFormat from "./GraphFormat.json";

export default function AdminDashboard() {
  return (
    <AdminSidebarNavbar>
      <div className="max-h-[calc(100vh-80px)] w-full p-4 overflow-y-auto">
        <div className="gap-4 grid grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
          {format.map((list, index) => (
            <DashboardCard
              key={index}
              title={list.title}
              color={list.color}
              api={list.api}
            />
          ))}
        </div>
        <div className="md:grid-cols-1 gap-4 md:gap-1 my-4 sm:grid-cols-1 grid grid-cols-2">
          {GraphFormat.map((list,index) => (
            <Graph
              key={index}
              title={list.title}
              api={list.api}
            />
          ))}
        </div>
        <div className="grid-cols-3 grid "></div>
      </div>
    </AdminSidebarNavbar>
  );
}

import { useState, Fragment } from "react";
import { addNotification } from "../../../Components/Notification";
import Token from "../../../Database/Token";
import Input from "../../../Components/Input/Input";

export default function CreditCardPay({ route, onLoading = () => {} }) {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mpin, setMpin] = useState();
  const [key,setKey] = useState(false);

  const deleteBooking = async () => {
    setLoading(true);
    const jsonData = {
      mpin: mpin,
    };
    try {
      const response = await Token.post(`${route}`,jsonData);
      console.log("Response:", response);

      if (response.data.status) {
        addNotification(
          "record deleted successfully",
          "Credit Card Bill has been paid",
          "success"
        );
        setKey(!key)
        setActive(false);
      } else {
        addNotification("Warning", response.data.message, "warning");
        console.log("Error message from server:", response.data.message);
      }
    } catch (e) {
      console.error("Error during API call:", e);
      addNotification("Error", e.message, "danger");
    } finally {
      setLoading(false);
      onLoading();
    }
  };

  return (
    <Fragment>
      <button
        className="bg-orange-400 rounded-md text-white font-bold hover:bg-orange-500 outline-none ring-0 focus:ring-orange-500 transition-all focus:ring-2 ring-offset-2"
        onClick={() => setActive(true)}
      >
        Pay Now
      </button>

      <div
        className={`z-[5] w-screen h-screen fixed inset-0 flex items-center justify-center p-3 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`bg-white max-w[400px] w-[400px] rounded-md shadow-2xl transition-all p-3 ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <h1 className="text-2xl font-bold text-orange-500">
            Enter MPIN number
          </h1>
          <p>It should be 6 digit</p>
          <div key={key}>
            <Input
              className="my-4"
              type="number"
              placeholder="Enter MPIN"
              onChange={(e) => setMpin(e.target.value)}
            />
          </div>

          <button
            className="outline-none my-[3px] w-full h-10 bg-orange-500 hover:bg-orange-600 focus-visible:ring-2 ring-offset-2 ring-red-500 text-white text-xl rounded-md shadow-md"
            onClick={deleteBooking}
          >
            Pay
          </button>
          <button
            className="w-full h-10 rounded-sm my-2 text-lg bg-white border-[1.5px] border-dashed border-gray-400 text-gray-500 shadow-md outline-none hover:bg-orange-50 focus:bg-orange-50"
            onClick={() => setActive(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}

import QRCode from "react-qr-code";
import UserSidebarNavbar from "../Components/User/UserSidebarNavbar";
import { useEffect, useState } from "react";
export default function Home(props) {
  const [value,setValue] = useState('')
  const [name,setName] = useState('')
  useEffect(()=>{
    const data = JSON.parse(localStorage.getItem('account'))
    const user = JSON.parse(localStorage.getItem('user'))
    setName(user.name)
    let string = "https://zokasta.com/home/scan?user="+user.name +"&upi_id="+ data.upi_id
    string = string.replace(/ /g,'%')
    setValue(string)
  },[])
  return (
    <UserSidebarNavbar>
      <div className="flex items-center justify-center h-[calc(100vh-40px)] bg-[#d8d8d8]">
      <div className="w-[300px] h-auto p-3 bg-[#f1f1f1] flex flex-col flex-row-reverse rounded-md shadow-md px-">
        <QRCode
          style={{ height: "auto", maxWidth: "270px", width: "270px" }}
          value={value}
          viewBox={`0 0 256 256`}
          // bgColor="f1f1f1"
        //   fgColor="000000"
        />
        <h1 className="text-center text-3xl font-bold text-orange-500 mt-2">{name}</h1>
      </div>
    </div>
    </UserSidebarNavbar>
  );
}

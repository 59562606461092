import { useEffect, useState } from "react";
import Token from "../../Database/Token";
import { addNotification } from "../../Components/Notification";

export default function Apply({ onclick = () => {} }) {
  const [localStorageData, setLocalStorageData] = useState({});
  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("user"));
    console.log(localData);
    setLocalStorageData(localData);
  }, []);
  const format = [
    { id: 1, title: "Full Name", target: "name", uppercase: true },
    { id: 2, title: "Email Address", target: "email", uppercase: false },
    { id: 3, title: "Phone Number", target: "phone", uppercase: true },
    { id: 4, title: "DOB", target: "dob", uppercase: true },
    { id: 5, title: "Pan Number", target: "pan_card", uppercase: true },
    { id: 6, title: "Aadhar Number", target: "aadhar_card", uppercase: true },
    // { id: 7, title: "Gender", target: "gender", uppercase: false },
  ];

  const applyCreditCard = async () => {
    try {
      const response = await Token.post("/credit-card/apply/");
      console.log(response);
      if (response.data.status) {
        addNotification("Success", response.data.message, "success");
        // onclick("credit");
      } else {
        addNotification("Warning", response.data.message, "warning");
        // onclick('credit')
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="p-4 min-h-[calc(100vh-40px)]">
      <div
        className="bg-white rounded-md shadow-md p-4 "
        key={"localStorageData"}
      >
        <div className="gap-4 grid grid-cols-2 sm:grid-cols-1">
          {format.map((list, index) => (
            <div key={index}>
              <p>{list.title}</p>
              <div
                defaultValue={localStorageData[list.target]}
                className={`${
                  list.uppercase ? "uppercase" : ""
                } w-full h-10 bg-[#f4f6f8] border-[1px] flex items-center pl-2`}
              >
                {localStorageData[list.target]}
              </div>
            </div>
          ))}
        </div>
        <button
          className="w-[100px] h-10 bg-orange-500 text-white rounded-md text-sm font-bold hover:bg-orange-600 transition duration-300 mt-4"
          onClick={() => applyCreditCard()}
        >
          Apply
        </button>
        <button
          className="w-[100px] h-10 bg-[#f4f6f8] text-gray-700 border-[1px] border-gray-300 rounded-md text-sm font-bold transition duration-300 mt-4 ml-4"
          onClick={() => onclick("noCard")}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

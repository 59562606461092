import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import UPIN from "../../Components/User/UPIN";
import Axios from "../../Database/Axios";
import {useNavigate, useParams, useLocation} from 'react-router-dom'
import { useState } from "react";

export default function PasswordOTP() {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading,setLoading] = useState(false);
  const params = useParams();
  const onPinSubmit = async (e) =>{
    setLoading(true)
    const data = {
      username: params.username,
      otp: e
    };

    try {
      const response = await Axios.post("/verify-otp-for-forgot-password/", data);
      console.log(response.data);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      
      if(response.data.status)
      {
        const location = '/forgot-password/page/' + params.username
        navigate(location, { state: { token: response.data.token } });
      }

    } catch (e) {
      console.log(e);
    }
    setLoading(false)
  }
  return (
    <div className="bg-[#e4e3e3] h-screen flex items-center justify-center">
      <div className="">
        <div className="w-[350px] h-[400px] shadow-md rounded-md bg-white p-3">
          <div className="text-center">
            <MarkEmailReadIcon
              style={{ color: "#f97316", width: "100px", height: "100px" }}
            />
          </div>
          <p className="text-center font-bold">Check your email box</p>
          <p className="text-center">We send OTP to your email box</p>
          <UPIN length={6} onPinSubmit={onPinSubmit}/>
          <p className="text-center mt-[3px]">
            Didn't get the code?{" "}
            <a className="text-orange-500 font-semibold" href="">
              Click to resend.
            </a>
          </p>
          <div className="grid grid-cols-2 gap-3 mt-5">
            <button className="text-center h-12 text-gray-500 text-xl rounded-lg  border-2 border-gray-300" onClick={()=>navigate('/')}>cancel</button>
            {loading && <button className="text-center h-12 bg-orange-500 text-white text-xl rounded-lg">Verify</button>}
            {!loading && <button className="text-center h-12 bg-orange-500 text-white text-xl rounded-lg">Loading</button>}
          </div>
        </div>
      </div>
    </div>
  );
}

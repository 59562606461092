import { useState, Fragment, useEffect } from "react";
import { addNotification } from "../../../Components/Notification";
import { IconButton, Tooltip } from "@mui/material";

import Token from "../../../Database/Token";
import Edit from "../../../Assets/SVG/Edit";
import Input from "../../../Components/Input/Input";

export default function EditPopup({
  title = "Enter Title",
  route,
  onLoading = () => {},
  format = [],
  data = [],
  alert = "row has been updated",
}) {
  const [active, setActive] = useState(false);
  const [formValue, setFormValue] = useState(data);

  const EditFunction = async () => {
    try {
      console.log(formValue);
      const response = await Token.patch(route, formValue);
      console.log("Response:", response);
      if (response.data.status) {
        addNotification("Successful booking", response.data.message, "success");
        setActive(false);
      } else {
        addNotification("Error", response.data.message, "danger");
        console.log("Error message from server:", response.data.message);
      }
    } catch (error) {
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
        console.log(error.response.data.message)
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    } finally {
      onLoading();
    }
  };

  return (
    <Fragment>
      <Tooltip arrow placement="top" title="Edit">
        <IconButton className="" onClick={() => setActive(true)}>
          <Edit />
        </IconButton>
      </Tooltip>

      <div
        className={`z-[5] w-screen h-screen overflow-y-auto  fixed inset-0 flex items-center justify-center pt-10 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`bg-white max-w[400px] w-[400px] rounded-md shadow-2xl transition-all p-3 ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <h1 className="text-5xl font-bold text-orange-500 text-center ">
            {title}
          </h1>
          {format.map((list, index) => (
            <div key={index}>
              <p className="font-bold">{list.title}</p>
              <Input
                type={list.type}
                className="w-full h-10 bg-[#f4f6f8] border-[2px] border-gray-300 rounded-sm outline-none focus:border-orange-500 px-2 focus:border-[2px] mb-2"
                defaultValue={formValue[list.target]}
                onChange={(e) =>
                  setFormValue((prev) => ({
                    ...prev,
                    [list.target]: e.target.value,
                  }))
                }
                options={list.options}
              />
            </div>
          ))}

          <button
            className="outline-none my-[3px] w-full h-10 bg-orange-500 hover:bg-orange-600 focus-visible:ring-2 ring-offset-2 ring-red-500 text-white text-xl rounded-md shadow-md"
            onClick={EditFunction}
          >
            Edit
          </button>
          <button
            className="w-full h-10 rounded-sm my-2 text-lg bg-white border-[1.5px] border-dashed border-gray-400 text-gray-500 shadow-md outline-none hover:bg-orange-50 focus:bg-orange-50"
            onClick={() => setActive(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}

import Visa from "../../Assets/Images/VISA-logo.png";
import { useEffect, useState } from "react";
import Eye from "../../Assets/SVG/Eye";
import EyeSlash from "../../Assets/SVG/EyeSlash";

export default function Card(props) {
  const [name, setName] = useState("");
  const [card, setCard] = useState("");
  const [valid, setValid] = useState("");
  const [cvv,setCVV] = useState("nothing there")
  const [eye,setEye] = useState(false);

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("credit_card_data"));
    const user = JSON.parse(localStorage.getItem("user"));

    setName(user.name);
    let debitCardNumber = account.card_number.toString();
    let formattedCard = debitCardNumber.replace(/(\d{4})(?=\d)/g, "$1 ");
    setCVV(account.cvv)
    setCard(formattedCard);

    setValid(account.expiration_date);
  }, []);

  return (
    <div className="grid grid-cols-[1fr_1fr] gap-4 overflow-auto w-full">
      <div className="max-w-[400px] w-full min-w-[310px] max-h-[220px] h-[220px] bg-orange-400 rounded-2xl shadow-md relative p-4">
        <img
          src={Visa}
          className="bg-[#e4e3e3] rounded-xl p-1 w-[100px] h-[60px]"
          alt="Image not found"
        />
        <p className="absolute bottom-12 ">
          <p className="text-white">Card Number</p>
          <p className="text-white font-medium text-2xl">{card}</p>
        </p>
        <p className="text-white font-bold text-xl bottom-0 absolute mb-3 ">
          {name}
        </p>
        <p className="font-semibold text-xl text-white right-0 m-3 top-0 absolute">
          Valid Thru <br /> <p className="font-extrabold">{valid}</p>
        </p>
      </div>
      <div className="max-w-[400px] w-full min-w-[310px] max-h-[220px] h-[220px] bg-orange-400 rounded-2xl shadow-md relative p-4">
        <p className="font-semibold text-xl text-white">
          CVV <br /> 
        <div className="grid grid-cols-[50px_30px] w-min ">
        <input className="bg-orange-400 outline-none w-min text-2xl " disabled={true} value={cvv} type={!eye?"password":"text"} />
          <div className="float-left scale-125 translate-y-[10px]" onClick={()=>setEye(!eye)}>
            { eye && <Eye className="fill-white"/>}
            { !eye && <EyeSlash className="fill-white" />}
          </div>
        </div>
        </p>
      </div>
    </div>
  );
}

import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Axios from "../Database/Axios";
import { addNotification } from "../Components/Notification";
import Spin from "../Assets/SVG/Spin";
import Input from "../Components/Input/Input";
import SelectCity from "./CreatedAccount/SelectCity";

export default function CreateAccount() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    aadhar_card: "",
    pan_card: "",
    password: "",
    phone: "",
    email: "",
    mpin: "",
    username: "",
    dob: "",
    gender: "",
    type: "user",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const callFunction = async () => {
    setLoading(true);
    console.log(formData);
    try {
      const response = await Axios.post("/signup/", formData);
      if (response.data.status) {
        localStorage.setItem("user", response.data.user);
        addNotification("success", response.data.otp, "success");
        localStorage.setItem("token", response.data.token);
        const location = "/otp/" + response.data.user.username;
        navigate(location);
      } else {
        addNotification("Warning", response.data.message);
      }
      console.log(response.data);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const fields = [
    { label: "Name", name: "name", type: "text", placeholder: "Enter Name" },
    {
      label: "Username",
      name: "username",
      type: "text",
      placeholder: "Enter Username",
    },
    { label: "DOB", name: "dob", type: "date", placeholder: "Enter Date of Birth" },
    {
      label: "Aadhar number",
      name: "aadhar_card",
      type: "number",
      placeholder: "Enter Aadhar number",
    },
    {
      label: "Pan number",
      name: "pan_card",
      type: "text",
      placeholder: "Enter Pan number",
    },
    {
      label: "Phone number",
      name: "phone",
      type: "number",
      placeholder: "Enter Phone number",
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      placeholder: "Enter Email address",
    },
    {
      label: "Password",
      name: "password",
      type: "password",
      placeholder: "Enter Password",
    },
    {
      label: "MPIN (6 Digits)",
      name: "mpin",
      type: "password",
      placeholder: "Enter MPIN",
    },
  ];

  return (
    <div className="flex items-center justify-center bg-[#e4e3e3] min-h-dvh p-3">
      <div className="max-w-[550px] w-[550px] min-w-[200px]">
        <div className="bg-white shadow-md rounded-md p-5">
          <h1 className="text-center text-4xl font-bold font-mono text-orange-500">
            Create Account
          </h1>
          <div className="grid-cols-2 grid sm:grid-cols-1 gap-x-4 sm:gap-y-1">
            {fields.map((field, index) => (
              <div key={index}>
                <p>{field.label}</p>
                <Input
                  defaultValue={formData[field.name]}
                  onChange={handleChange}
                  name={field.name}
                  type={field.type}
                  placeholder={field.placeholder}
                />
              </div>
            ))}

            {/* Gender select dropdown */}
            <div>
              <p>Gender</p>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className="w-full h-10 border-gray-300 rounded-sm px-2 border-[1px] bg-[#f4f6f8]"
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>

            <SelectCity onClick={(e) => setFormData({ ...formData, city: Number(e.target.value) })} />
          </div>
          <br />
          {!loading && (
            <button
              className="h-10 rounded-sm bg-orange-500 w-full text-white text-xl hover:bg-orange-600 outline-none focus-visible:ring-2 ring-offset-2 ring-orange-500"
              onClick={callFunction}
            >
              Continue
            </button>
          )}
          {loading && (
            <button className="h-10 rounded-sm bg-orange-500 w-full text-white text-xl hover:bg-orange-600 outline-none focus-visible:ring-2 ring-offset-2 ring-orange-500 flex items-center justify-center">
              <Spin />
            </button>
          )}
        </div>
        <br />
        <Link to="/">
          <p className="text-center cursor-pointer">Already have Account! Login Now</p>
        </Link>
      </div>
    </div>
  );
}

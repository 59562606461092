import { useState, useRef, useEffect } from "react";

export default function UPIN({ length = 6, onPinSubmit = () => {} }) {
  const [pin, setPin] = useState(Array(length).fill(""));
  const inputRef = useRef([]);

  useEffect(() => {
    inputRef.current[0]?.focus();
  }, []);

  const handleChange = (index, e) => {
    const value = e.target.value;

    // Allow only single digit
    if (value && (isNaN(value) || value.length > 1)) return; 

    const newPin = [...pin];
    newPin[index] = value ? value.substring(value.length - 1) : ""; // Handle empty string
    setPin(newPin);

    const joinData = newPin.join("");

    // Move focus to the next input if the value is not empty
    if (value && index < length - 1) {
      inputRef.current[index + 1]?.focus();
    }

    // Call onPinSubmit when the last digit is filled
    if (value && index === length - 1) {
      onPinSubmit(joinData);
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !pin[index] && index > 0) {
      inputRef.current[index - 1]?.focus();
    }
  };

  return (
    <div className="flex justify-center mt-14">
      <div>
        {pin.map((value, index) => (
          <input
            key={index}
            type="number" // Set input type to number
            value={value}
            ref={(input) => (inputRef.current[index] = input)}
            onChange={(e) => handleChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            className="h-12 outline-orange-500 border-gray-300 rounded-sm mx-[1.5px] text-center border-[2px] float-left w-12 text-lg"
            maxLength={1}
            min={0} // Minimum value
            max={9} // Maximum value
          />
        ))}
      </div>
    </div>
  );
}

import { useState, Fragment } from "react";
import { addNotification } from "../../../Components/Notification";
import { IconButton, Tooltip } from "@mui/material";

import Token from "../../../Database/Token";
import Loading from "../../../Assets/SVG/Loading";
import AutorenewIcon from "@mui/icons-material/Autorenew";

export default function CreatePopUp({
  title = "Rollback",
  api,
  hover = "Rollback",
  onUpdate = () => {},
}) {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const EditFunction = async () => {
    setLoading(true);
    try {
      const response = await Token.post(api);
      console.log("Response:", response);
      if (response.data.status) {
        addNotification("Successful Rollback", response.data.message, "success");
        setLoading(false);
        setActive(false);
        onUpdate();
      } else {
        addNotification("Warning", response.data.message, "warning");
        console.log("Error message from server:", response.data.message);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    }
  };

  return (
    <Fragment>
      <Tooltip arrow placement="top" title={hover}>
        <div className="bg-orange-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md m-[11px]">
          <IconButton
            className="w-10 h-10 rounded-none"
            onClick={() => setActive(!active)}
          >
            <AutorenewIcon className="scale-125 text-white" />
          </IconButton>
        </div>
      </Tooltip>

      <div
        className={`z-[5] w-screen h-screen overflow-y-auto  fixed inset-0 flex items-center justify-center pt-10 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`bg-white max-w[400px] w-[400px] rounded-md shadow-2xl transition-all p-3 ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <h1 className="text-5xl font-bold text-orange-500 text-center ">
            {title}
          </h1>
          <p className="my-1">
            Are you sure to rollback transaction. if you do then it's not have
            any back
          </p>
          {!loading && (
            <button
              className="outline-none my-[3px] w-full h-10 bg-orange-500 hover:bg-orange-600 focus-visible:ring-2 ring-offset-2 ring-red-500 text-white text-xl rounded-md shadow-md"
              onClick={EditFunction}
            >
              Rollback
            </button>
          )}
          {loading && (
            <button className="outline-none my-[3px] w-full h-10 bg-orange-500 hover:bg-orange-600 focus-visible:ring-2 ring-offset-2 ring-red-500 text-white text-xl rounded-md shadow-md flex items-center justify-center">
              <Loading />
            </button>
          )}
          <button
            className="w-full h-10 rounded-sm my-2 text-lg bg-white border-[1.5px] border-dashed border-gray-400 text-gray-500 shadow-md outline-none hover:bg-orange-50 focus:bg-orange-50"
            onClick={() => setActive(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}

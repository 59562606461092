import { Routes, Route } from "react-router-dom";


// Others and auth pages

import Login from "./Pages/User/Login/Login";
import CreateAccount from "./Pages/CreateAccount";
import NotFound from "./Pages/NotFound";
import OTP from "./Pages/OTP";
import AdminDashboard from "./Pages/Admin/AdminDashboard/AdminDashboard";
import ForgotPassword from "./Pages/Other/ForgotPassword";
import PasswordOTP from "./Pages/Other/PasswordOTP";
import ForgotPasswordMain from './Pages/Other/ForgotPasswordMain'

// Users pages

import Home from "./Pages/Home/Home";
import AdminLogin from "./Pages/AdminLogin";
import QrReader from "./Pages/Scan/QrReader";
import QrCode from "./Pages/QrCode";
import Account from "./Pages/Account/Account";
import Transaction from "./Pages/Transaction";
import Report from "./Pages/Report";
import SendMoneyByPhone from "./Pages/SendMoneyByPhone";
import CreditCard from "./Pages/CreditCard/CreditCard";


// Admin pages

import AdminUser from "./Pages/Admin/AdminUser/AdminUser";
import AdminUserAccount from "./Pages/Admin/AdminUserAccount/AdminUserAccount";
import AdminTransaction from "./Pages/Admin/AdminTransaction/AdminTransaction";
import AdminAccount from "./Pages/Admin/AdminAccount/AdminAccount";
import AdminCreditCard from "./Pages/Admin/CreditCard/AdminCreditCard";

// other 
import "react-notifications-component/dist/theme.css";
import CreditCardRequest from "./Pages/Admin/CreditCardRequest/CreditCardRequest";
import AdminState from "./Pages/Admin/AdminState/AdminState";
import AdminCity from "./Pages/Admin/AdminCity/AdminCity";


export default function App() {
  return (
    <Routes>
      {/* Others */}
      <Route path="*" element={<NotFound />} />
      <Route path="/otp/:username" element={<OTP />} />
      <Route path="/forgot-password/otp/:username" element={<PasswordOTP />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/forgot-password/page/:username" element={<ForgotPasswordMain />}/>

      {/* Users Routes */}
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/create-account" element={<CreateAccount />} />
      <Route path="/home" element={<Home />} />
      <Route path="/home/scan" element={<QrReader />} />
      <Route path="/home/qr-code" element={<QrCode />} />
      <Route path="/home/account" element={<Account />} />
      <Route path="/home/transaction" element={<Transaction />} />
      <Route path="/home/credit-card" element={<CreditCard />} />
      <Route path="/home/report" element={<Report />} />
      <Route path="/home/send-money-by-phone" element={<SendMoneyByPhone />} />


      {/* Admin Routes */}
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="/admin/dashboard" element={<AdminDashboard />} />
      <Route path="/admin/accounts" element={<AdminUserAccount />} />
      <Route path="/admin/user" element={<AdminUser />} />
      <Route path="/admin/transaction" element={<AdminTransaction />} />
      <Route path="/admin/account" element={<AdminAccount />} />
      <Route path="/admin/credit-card" element={<AdminCreditCard />} />
      <Route path="/admin/credit-card-request" element={<CreditCardRequest />} />
      <Route path="/admin/state" element={<AdminState />} />
      <Route path="/admin/city" element={<AdminCity />} />


    </Routes>
  );
}


import { useState, useEffect } from "react";
import ThreeDot from "../../Assets/SVG/ThreeDot";
import Token from "../../Database/Token";
import { addNotification } from "../Notification";

export default function DashboardCard({
  title = "Title Hear",
  api = "Something wrong",
  color = "bg-[#f97316]",
  hover = "hover:bg-[#f95a16]",
}) {


  const [displayAmount, setDisplayAmount] = useState(0);
  const [change, setChange] = useState(100);
  const [threeDot, setThreeDot] = useState(false);
  const [time, setTime] = useState("day");

  useState(() => {
    const data = localStorage.getItem(`${title}HomePage`);
    if (data) {
      setTime(data);
    } else {
      localStorage.setItem(`${title}HomePage`, "day");
    }
  }, []);

  useEffect(() => {
    const callFunction = async () => {
      try {
        const response = await Token.get(`${api}${time}`);
        if (response.data.status) {
          const count = response.data.data.current
          setDisplayAmount(count.toLocaleString());
          setChange(response.data.growth);
        }
      } catch (e) {
        addNotification("Error", e.message, "danger");
      }
    };
    callFunction();
  }, [time]);

  const threeDotClick = (e) => {
    e.stopPropagation();
    setThreeDot(!threeDot);
  };

  const clickTime = async (e) => {
    localStorage.setItem(`${title}HomePage`, e);
    setTime(e);
  };

  return (
    <div
      className={`${color} ${hover} py-3 pl-3 w-full h-32 rounded-md shadow-md`}
    >
      <p className="text-white text-3xl w-min float-left">
        {displayAmount.toString()}
      </p>
      <p className="text-gray-200 text-xl float-left ml-2 mt-1">({change}%)</p>
      <div className="relative" onClick={threeDotClick}>
        <ThreeDot className="float-right mr-1 hover:bg-gray-200/30 duration-300 w-10 h-10 rounded-full p-2 " />
        <div
          className={`${
            threeDot
              ? "opacity-100 scale-100 pointer-events-auto"
              : "opacity-0 scale-75 pointer-events-none"
          } duration-200 w-20 h-auto bg-white rounded-md shadow-md absolute right-3 top-8 cursor-pointer z-[2]`}
        >
          <div
            onClick={() => clickTime("day")}
            className={`${
              time === "day" ? "bg-orange-500 text-white" : "bg-white"
            } rounded-t-md h-8 hover:bg-orange-500 border-b-[0.5px] border-gray-100 uppercase flex items-center justify-center hover:text-white text-center`}
          >
            {" "}
            Day{" "}
          </div>
          <div
            onClick={() => clickTime("week")}
            className={`${
              time === "week" ? "bg-orange-500 text-white" : "bg-white"
            } h-8 hover:bg-orange-500 border-b-[0.5px] border-gray-100 uppercase flex items-center justify-center hover:text-white text-center`}
          >
            {" "}
            Week{" "}
          </div>
          <div
            onClick={() => clickTime("month")}
            className={`${
              time === "month" ? "bg-orange-500 text-white" : "bg-white"
            } h-8 hover:bg-orange-500 border-b-[0.5px] border-gray-100 uppercase flex items-center justify-center hover:text-white text-center`}
          >
            {" "}
            Month{" "}
          </div>
          <div
            onClick={() => clickTime("year")}
            className={`${
              time === "year" ? "bg-orange-500 text-white" : "bg-white"
            } h-8 hover:bg-orange-500 border-b-[0.5px] border-gray-100 uppercase flex items-center justify-center hover:text-white text-center`}
          >
            {" "}
            Year{" "}
          </div>
          <div
            onClick={() => clickTime("all")}
            className={`${
              time === "all" ? "bg-orange-500 text-white" : "bg-white"
            } h-8 hover:bg-orange-500 border-b-[0.5px] border-gray-100 uppercase flex items-center justify-center hover:text-white text-center rounded-b-md`}
          >
            {" "}
            All{" "}
          </div>
        </div>
      </div>
      <br /> <br />
      <p className="text-gray-200">{title}</p>
      <p className="text-gray-200 uppercase">{time}</p>
    </div>
  );
}

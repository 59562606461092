
import UserSidebarNavbar from "../Components/User/UserSidebarNavbar";

export default function SendMoneyByPhone(props) {
  return (
    <UserSidebarNavbar>
     
    </UserSidebarNavbar>
  );
}

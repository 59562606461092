import { useState } from "react";
import { Password } from "./Password";
import CopyInput from "./CopyInput";

export default function Input({
  defaultValue = "",
  type = "text",
  className = "",
  placeholder = "",
  valid = false,
  onChange = (e) => {},
  name = "name",
  options = [],
  readOnly=false,
}) {
  const [value, setValue] = useState(defaultValue);
  return type === "password" ? (
    <Password
      onChange={onChange}
      className={className}
      defaultValue={defaultValue}
      placeholder={placeholder}
      name={name}
      readOnly={readOnly}
    />
  ) : type === "textarea" ? (
    <textarea
      className={`w-full min-h-10 h-20 bg-[#f4f6f8] border-[1px] border-gray-300 rounded-sm outline-none focus:border-orange-500 px-2 focus:border-[1.75px]  ${className}`}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e);
      }}
    ></textarea>
  ) : type === "select" ? (
    <select
      className={`w-full h-10 bg-[#f4f6f8] border-[1px] border-gray-300 rounded-sm outline-none focus:border-orange-500 px-2 focus:border-[1.9px] ${className}`}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e);
      }}
    >
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.title}
        </option>
      ))}
    </select>
  ) : type === "selectFromApi" ? (
    ""
  ) : type === "copy" ? (
    <CopyInput
      // onChange={onChange}
      className={className}
      defaultValue={defaultValue}
      placeholder={placeholder}
      name={name}
    />
  ) : (
    <input
      type={type}
      className={`w-full h-10 bg-[#f4f6f8] border-[1px] ${
        valid ? "border-gray-300" : "border-gray-300"
      } rounded-sm outline-none focus:border-orange-500 px-2 focus:border-[1.9px] ${className}`}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e);
      }}
      placeholder={placeholder}
      name={name}
    />
  );
}

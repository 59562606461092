import { useState, useEffect } from "react";
import { useAppContext } from "../../Database/Context";
import UserSidebarNavbar from "../../Components/User/UserSidebarNavbar";
import Token from "../../Database/Token";
import Search from "../../Components/Search";
import Table from "../../Components/Table/Table";

export default function CreditTransaction() {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [row, setRow] = useState(5);
  const [order, setOrder] = useState("desc");
  const [title, setTitle] = useState("id");
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);

  const [TableData, setTabalData] = useState([]);

  const { dispatch } = useAppContext();

  const colName = [
    {
      id: 1,
      title: "id",
      target: "index",
      status: true,
    },
    {
      id: 2,
      title: "amount",
      target: "amount",
      status: true,
    },
    {
      id: 3,
      title: "date",
      target: "timestamp",
      status: true,
    },
    {
      id: 5,
      title: "person",
      target: "name",
      status: true,
    },
    {
      id: 4,
      title: "status",
      target: "status",
      status: true,
    },
    {
      id: 6,
      title: "action",
      target: "action",
      status: false,
    },
  ];

  useEffect(() => {
    console.log('hello')
    const callFunction = async () => {
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await Token.get(`transaction?search=${search}&type=CC`);
        console.log(response);  
        setData(response.data.transactions)
      } catch (e) {
        console.log(e.message);
      }
      dispatch({ type: "SET_LOADING", payload: false });
    };
    callFunction();
  }, [search, update]);
  
  const searchChange = (e) => {
    setSearch(e);
    setUpdate(!update);
    console.log("update")

  };

  return (
    // <UserSidebarNavbar>
      <div className="w-full mt-4 overflow-y-auto">
        <Search className="mb-4" onSearch={searchChange}></Search>
        <div key={TableData}>
          <Table
            data={data}
            button={""}
            updateRow={(e) => {
              setRow(e);
              setPage(1);
            }}
            cPage={TableData.current_page}
            updatePage={(e) => setPage(e)}
            lPage={TableData.last_page}
          >
            <thead className="text-xs text-gray-100 uppercase bg-orange-500">
              <tr>
                {colName.length !== 0 &&
                  colName.map((data) => (
                    <th
                      key={data.id}
                      scope="col"
                      colSpan={1}
                      className="duration-300 pl-2 sm:pl-1 py-2 group h-14 min-w-[75px]"
                    >
                      {data.title}
                    </th>
                  ))}
              </tr>
            </thead>

            <tbody>
              {data.length === 0 && (
                <tr>
                  <td
                    colSpan={colName.length}
                    className="text-center text-bold text-xl text-gray-800 uppercase py-4 border-[1.5px] border-gray-300"
                  >
                    Data not found
                  </td>
                </tr>
              )}
              {data.length !== 0 &&
                data.map((list) => (
                  <tr
                    // className={`pl-2 ${ list.status === "debit"?"bg-[#ffdddd] hover:bg-[#ffb0b0]":"bg-[#c2ecff] hover:bg-[#95deff]"} text-slate-900 border-y-[1.7px]`}
                    className={`pl-2 text-slate-900 border-y-[1.7px] hover:bg-[#f1f1f1]`}
                    key={list.id}
                  >
                    {colName.map((data) => (
                      <>
                        {data.status && (
                          <th
                            className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                            scope="row"
                          >
                            {list[data.target]}
                          </th>
                        )}
                      </>
                    ))}
                    <th
                      className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                      scope="row"
                    ></th>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    // </UserSidebarNavbar>
  );
}

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import UserSidebarNavbar from "../../Components/User/UserSidebarNavbar";
import CreditTransaction from "../../Components/User/CreditTransaction.";
import Card from "./Card";
import Spin from "../../Assets/SVG/Spin";
import Apply from "./Apply";
import PaymentBar from "./PaymentBar";
import Token from "../../Database/Token";

export default function CreditCard() {
  const [state, setState] = useState("loading");
  const history = useNavigate();

  useEffect(() => {
    const callFunction = async () => {
      try {
        const response = await Token.get("/credit-card/check/");
        console.log(response.data.credit_card_request);
        const res = response.data.credit_card_request;
        console.log(res);
        if (res == "confirm") {
          localStorage.setItem(
            "credit_card_data",
            JSON.stringify(response.data.data)
          );
          setInterval(() => {
            setState("credit");
          }, 300);
        } else if (res == "NoCreditCardFound") {
          setState("noCard");
        } else {
        }
        setState(response.data.credit_card_request);
      } catch (e) {
        setState("noCard");
        console.log(e);
      }
    };
    callFunction();
  }, []);
  return (
    <UserSidebarNavbar>
      {state === "credit" && (
        <div className="p-4 w-full">
          <Card />
          <PaymentBar />
          <CreditTransaction />
        </div>
      )}
      {state === "loading" ? (
        <div className="flex items-center justify-center h-[calc(100vh-40px)]">
          <div className="scale-[500%]">
            <Spin />
          </div>
        </div>
      ) : null}
      {state === "applyForCreditCard" ? (
        <Apply onclick={(e) => setState(e)} />
      ) : null}
      {state === "noCard" ? (
        <div className="flex items-center justify-center h-[calc(100vh-40px)]">
          <div className="">
            <h1 className="text-center text-orange-400 text-4xl uppercase font-bold md:text-base">
              Sorry, You don't have Credit Card
            </h1>
            <p className="text-center mt-6 font-medium max-w-[600px] text-[#676e73]">
              Click This Button For Apply Credit Card. After the apply we will
              verify and in 3-4 working day we will active your credit card
            </p>
            <div className="grid grid-cols-2 gap-6 mt-5">
              <div>
                <button
                  className="w-52 float-right h-14 rounded-full bg-orange-500 text-white text-2xl sm:w-40 focus-visible:ring-2 ring-offset-2 ring-orange-500 outline-none"
                  onClick={() => setState("applyForCreditCard")}
                >
                  Apply
                </button>
              </div>
              <button
                className="w-52 h-14 rounded-full border-2 border-orange-500 text-orange-500 sm:w-40 text-2xl font-medium outline-none focus-visible:ring-2 ring-offset-2 ring-orange-500"
                onClick={() => history("/home")}
              >
                Home
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {(state === "pending") && (
        <div className="flex items-center justify-center h-[calc(100vh-40px)]">
          <div className="">
            <h1 className="text-center text-orange-400 text-4xl uppercase font-bold md:text-base">
              Wait For Your Application Confirm,
            </h1>
            <p className="text-center mt-6 font-medium max-w-[600px] text-[#676e73]">
              You need to wait 2-3 working days to use confirm the credit card
              and issue for you
            </p>
            <div className="grid grid-cols-2 gap-6 mt-5">
              {/* <div>
                <button
                  className="w-52 float-right h-14 rounded-full bg-orange-500 text-white text-2xl sm:w-40 focus-visible:ring-2 ring-offset-2 ring-orange-500 outline-none"
                  onClick={() => setState("applyForCreditCard")}
                >
                  Apply
                </button>
              </div>
              <button
                className="w-52 h-14 rounded-full border-2 border-orange-500 text-orange-500 sm:w-40 text-2xl font-medium outline-none focus-visible:ring-2 ring-offset-2 ring-orange-500"
                onClick={() => history("/home")}
              >
                Home
              </button> */}
            </div>
          </div>
        </div>
      )}

      {state == "rejected" && (
        <div className="flex items-center justify-center h-[calc(100vh-40px)]">
          <div className="">
            <h1 className="text-center text-orange-400 text-4xl uppercase font-bold md:text-base">
              Your application has been rejected.
            </h1>
            <p className="text-center mt-6 font-medium max-w-[600px] text-[#676e73]">
              if you want then you can contact to our mail contact@zokasta.com 
            </p>
            <div className="grid grid-cols-2 gap-6 mt-5">
            </div>
          </div>
        </div>
      )}
    </UserSidebarNavbar>
  );
}

import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import Token from "../../Database/Token";

const Graph = (props) => {
  const [data, setData] = useState([0, 0, 0, 0, 0, 0]);
  const [xData, setXData] = useState([1, 2, 3, 4, 5, 6]);

  useEffect(() => {
    const callFunction = async () => {
      try {
        const response = await Token.get(props.api);
        if (response.data.status) {
          let list1 = Object.values(response.data['x-axis'])
          let list2 = Object.values(response.data['y-axis'])

          setXData(list2);
          setData(list1);
        }
      } catch (e) {
        console.log(e.message);
      }
    };
    callFunction();
  }, []);

  const options = {
    title: "Monthly Bookings",
  };

  return (
    <div className="bg-white p-4 md:p-1 sm:p-1 rounded-md shadow-md" key={data}>
      <p className="text-center">{props.title}</p>
      <Stack sx={{ width: "100%" }}>
        <LineChart
          xAxis={[{ data: xData, scaleType: "point" }]}
          series={[{ data, connectNulls: true }]}
          height={200}
          margin={{ top: 10, bottom: 20 }}
          options={options}
        />
      </Stack>
    </div>
  );
};

export default Graph;
